// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-car-details-js": () => import("./../../../src/templates/CarDetails.js" /* webpackChunkName: "component---src-templates-car-details-js" */),
  "component---src-templates-cars-directory-js": () => import("./../../../src/templates/carsDirectory.js" /* webpackChunkName: "component---src-templates-cars-directory-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

